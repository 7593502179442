// Import the US Web Design System minified CSS
//@import "../uswds/css/uswds.min.css";

// Alternatively, you could import the SCSS files directly for USWDS mixins and variables.
// @note USWDS 2.2.1 custom sass fails to compile with jekyll (see github issues), 2.0.0 lacks needed tokens
//$theme-font-path:   '../uswds/fonts';
//$theme-image-path:  '../uswds/img';
//$theme-banner-max-width:              'widescreen';
//$theme-header-max-width:              'widescreen';
//$theme-header-min-width:              'tablet';
//$theme-grid-container-max-width: 'widescreen';
//$theme-site-max-width:              'widescreen';
//$theme-site-margins-breakpoint:     'widescreen';
//$theme-text-measure:               6;
//$theme-utility-breakpoints: (
//        'card':             false,   // 160px
//        'card-lg':          false,   // 240px
//        'mobile':           false,   // 320px
//        'mobile-lg':        true,    // 480px
//        'tablet':           true,    // 640px
//        'tablet-lg':        false,   // 800px
//        'desktop':          true,    // 1024px
//        'desktop-lg':       false,   // 1200px
//        'widescreen':       true,    // 1400px
//);
//@import 'uswds/uswds';

// This is a precompiled USWDS with the customized settings in uswds-theme-components
@import "../uswds-2.13.0/css/uswds.min.css";

// This is the main CSS file. You can write your SCSS/SASS in _sass and import
// them here. You probably want to load them _after_ uswds in order to override
// any uswds styles.
@media screen and (min-width: 640px) {
  .tablet\:width-1\/3 {
    width: 33%;
  }
  .tablet\:float-left {
    float: left;
  }
}

@media screen and (max-width: 1023px) {
  .usa-prose>p {
    max-width: unset;
    line-height: 1.4;
  }
}

@media screen and (max-width: 39.99875em) {
  .hide-for-small-only {
    display: none !important; }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; }
}

// Set globals

html {
  scroll-behavior:smooth
}

body, h1, h2, h3, h4, h5, h6, p, li, dd, dt, a {
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
  // font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
}

h1, .usa-prose > h1 {
  font-size:1.6rem;
  line-height:1.2;
  font-weight:bold;
}
h2, .usa-prose > h2 {
  font-size:1.45rem;
  line-height:1.2;
  font-weight:bold;
}
h3, .usa-prose > h3 {
  font-size:1.3rem;
  line-height:1.2;
  font-weight:bold;
}
h4, .usa-prose > h4 {
  font-size:1.15rem;
  line-height:1.2;
  font-weight:bold;
}
h5, .usa-prose > h5 {
  font-size:1rem;
  line-height:1.2;
  font-weight:bold;
}
h6, .usa-prose > h6 {
  font-size:0.9rem;
  line-height:1.1;
  letter-spacing:0.025em;
}

a, .usa-prose > a, .usa-link {
  color:#005ea2;
}

a:hover, .usa-prose > a:hover, .usa-link:hover {
  color: #005eb6;
  text-decoration: underline;
}

a:active, .usa-prose > a:active, .usa-link:active {
  color:#162e51;
}

a:focus, .usa-prose > a:focus, .usa-link:focus {
  outline:0.25rem solid #2491ff;
}

a:visited, .usa-prose > a:visited, .usa-link:visited {
  color:#005ea2;
}

.usa-section--dark .usa-link {
  color:#ffffff;
}

.usa-prose h3, .usa-prose h4, .usa-prose h5 {
  margin-bottom: -6px;
  line-height: 1.3;
}

h3.usa-alert__heading {
  margin-bottom:0.5rem;
}

.usa-prose blockquote {
  max-width: 92ex;
}

.usa-prose li p {
  margin: 0;
}

@media screen and (min-width: 40em) {
  .tablet\:justify-text {
    text-align: justify;
    text-justify: inter-word;
  }
  .tablet\:margin-top-15 {
    margin-top:7.5rem;
  }
  .tablet\:margin-bottom-25 {
    margin-bottom:12.5rem;
  }
}

blockquote {
  border-left: 8px solid #e5e8ed;
  padding: 1px 0 1px 20px;
}

code.highlighter-rouge {
  font-size: 1rem;
  background-color: #dddddd;
}

.align-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.align-bottom {
  position: absolute;
  bottom: 0;
}

// Backgrounds

.adl-bg-light {
  background-color: #f1f3f5;
}
@media screen and (min-width: 40em) {
  .tablet\:adl-bg-light {
    background-color: #f1f3f5;
  }
}

// Callouts

.callout {
  padding: 0 12px 12px 12px;
  color: #1b1b1b;
  background-color: #ffffff;
  border-radius: 0.75rem;
}

.callout a, .callout a:hover, .callout a:visited,
.callout .usa-link, .callout .usa-link:hover, .callout .usa-link:visited{
  color: #005ea2;
}

.callout h1, .callout h2, .callout h3, .callout h4, .callout h5, .callout h6 {
  color: #1b1b1b;
  line-height: 1;
  margin-block-start: 1em;
  margin-block-end: 0.5em;
  margin-bottom: 0.5em;
}

.callout p {
  color: #1b1b1b;
  margin-block-start: 0;
  margin-block-end: 0.5em;
  margin: 0 0 0.5em 0;
}

@media screen and (max-width: 39.99em) {
  .mobile-only\:padding-top-4 {
    padding-top: 2rem;
  }
  .mobile-only\:grid-col-6 {
    -webkit-box-flex:0;
    -ms-flex:0 0 auto;
    flex:0 0 auto;
    width:50%;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.99em) {
  .tablet-only\:grid-col-3 {
    -webkit-box-flex:0;
    -ms-flex:0 0 auto;
    flex:0 0 auto;
    width:25%;
  }
  .tablet-only\:grid-col-4 {
    -webkit-box-flex:0;
    -ms-flex:0 0 auto;
    flex:0 0 auto;
    width:33.33333%;
  }
  .tablet-only\:grid-col-8 {
    -webkit-box-flex:0;
    -ms-flex:0 0 auto;
    flex:0 0 auto;
    width:66.66667%;
  }
  .tablet-only\:grid-col-9 {
    -webkit-box-flex:0;
    -ms-flex:0 0 auto;
    flex:0 0 auto;
    width:75%;
  }
}


// Changes to the USA Gov Banner

.usa-banner__header {
  background-color: #000;
}

.usa-banner .usa-accordion {
  background-color: #000;
}

.usa-banner__content, .usa-banner__header {
  color: #fff;
}

.usa-banner__header-text, .usa-banner__button-text {
  color: #ffffff;
}
.usa-banner__button::after {
  background-image:url("../images/uswds/chevron-white.svg") no-repeat center/1.3128205128ex 0.8ex;
  background-color: #ffffff;
}
.usa-banner__button:hover::after {
  background-image:url("../images/uswds/chevron-white.svg") no-repeat center/1.3128205128ex 0.8ex;
  background-color: #ffffff;
}
.usa-banner__button[aria-expanded=true]::after{
  background-image:url("../images/uswds/chevron-white.svg") no-repeat center/1.3128205128ex 0.8ex;
  background-color: #ffffff;
}
.usa-banner__button[aria-expanded=true]:hover::after{
  background-image:url("../images/uswds/chevron-white.svg") no-repeat center/1.3128205128ex 0.8ex;
  background-color: #ffffff;
}
.usa-banner__header-action {
  color: #ffffff;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) or (-webkit-mask: url("")){
  .usa-banner__button[aria-expanded=true]::after:hover{
    background-color:#ffffff;
  }
}

@media (max-width: 39.99em) {
  // .usa-banner__header-action:after {
    // background-image: url("../images/uswds/chevron-white.svg") no-repeat center/1.3128205128ex 0.8ex;
  // }
  .usa-banner__button[aria-expanded=true]:after {
    background-color: #005ea2;
  }
}

// Header Changes

.usa-header{
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

.usa-header--extended {
  background-color: #069;
}

// Remove pipe from secondary links

.usa-nav__secondary-links .usa-nav__secondary-item+.usa-nav__secondary-item:before {
  content:'';
}
// .usa-nav__secondary-links .usa-nav__secondary-item {
//   padding-left: 0;
// }

// Manage the mobile header; create a background for the mobile secondary links

@media (max-width: 39.99em) {

  .usa-navbar {
    border: none;
    height: 3rem;
    color: #fff;
    background-color: #069;
  }

  .usa-menu-btn, .usa-menu-btn:visited {
    background-color: #3f76c5;
  }

  .usa-menu-btn:hover {
    background-color: #58abfb;
  }

  .usa-header--extended {
    border-bottom: 3px solid #d4d7db;
  }

  .usa-nav__secondary-links {
    margin: 0;
    padding: 6px 0 3px 0;
    text-align: center;
  }

  .usa-nav__secondary-links .usa-nav__secondary-item {
    display: inline;
    padding-right: 3px;
  }
}

// Menu Navigation Adjustments

.usa-nav .usa-accordion{
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

.usa-nav__primary > .usa-nav__primary-item > a {
  color: #162E51;
}

.usa-nav__primary button {
  color: #162E51;
}

.usa-nav__primary a:not(.usa-button):not(.usa-current) {
  color: #162E51;
}

@media (min-width: 40em) {
  .usa-nav.site-nav-secondary .usa-nav__primary-item {
    font-size: 1.2rem;
  }
  .usa-nav__secondary {
    bottom: 5rem;
  }
}


// Adjust the main logo and text

.usa-logo__text {
  float:left;
  overflow-y: hidden;
}
.usa-logo__title {
  padding-left: 10px;
  float: left;
  width: 15rem;
  height: 54px;
  line-height: 54px;
  font-size: 0.9rem;
  font-weight: 400;
}
.usa-logo__title span {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
}
.usa-logo__text img {
  width: unset;
  height: 2.8rem;
  margin-top: 4px;
}

@media (min-width: 40em) {
  .usa-header--extended .usa-nav {
    background-color: #fff;
    border-top: 5px solid #e7f6f8;
    border-bottom: 5px solid #e7f6f8;
  }
  .usa-header--extended .usa-logo {
    margin: 0 0 0.6rem;
    max-width: 100%;
  }
  .usa-logo__text img {
    width: 188px;
    height: unset;
  }
  .usa-logo__title {
    padding-left: 20px;
    width: calc(100vw - 32rem);
    color: #ffffff;
    font-size: 1.4rem;
    height: 128px;
    line-height: 128px;
    font-weight: bold;
  }
  .usa-logo__title span {
    line-height: 25px;
  }
  .usa-nav__secondary-links {
    font-size: 3rem;
  }
  .usa-search .usa-input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;

  }
  .usa-search.usa-search--small .usa-input {
    border: 1px solid #ffffff;
  }
  .usa-search .usa-button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .usa-header--extended .usa-current:after, .usa-header--extended .usa-nav__link:hover:after, .usa-header--extended [aria-expanded=true]:after {
    height:0.4rem;
  }
  .usa-header+.usa-section, .usa-header+main {
    border-top: none;
  }
  .usa-nav-link:hover span {
    border-bottom: 0.7rem solid #0071bc;
  }
  .usa-section {
    padding-top: 2rem;
  }
}
@media (min-width: 52em) {
  .usa-logo__title {
    font-size: 1.4rem;
  }
}
@media (min-width: 62em) {
  .usa-logo__title {
    font-size: 1.4rem;
  }
} 
@media (min-width: 64em) {
  .usa-logo__title {
    width: 44rem;
    font-size: 1.2rem;
  }
}

// IE10/11 specific
@media (min-width: 40em) {
  *::-ms-backdrop, .usa-logo__text img {
    width: auto;
    max-height: 100%;
    height: inherit;
    max-width: 186px;
  }
}

// Adjust the Main Content to force footer to bottom

#main-content {
  min-height: calc(100vh - 444px);
}

// Input

.usa-input, .usa-range, .usa-select, .usa-textarea {
  border: 1px solid #578edd;
  border-radius: 0.25rem;
}

// Buttons

// This button is used in .md files because the class is applied to the paragraph not the link
.adl-button a {
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 1.06rem;
  line-height: 0.9;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #005ea2;
  border: 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.5rem;
  padding: 0.75rem 1.25rem;
  text-align: center;
  text-decoration: none;
}

.usa-button{
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

.usa-button, .usa-button:visited,
.adl-button a, .adl-button a:visited {
  border-radius: 0.25rem;
  color: #ffffff !important;
  background-color: #3f76c5;
}
.usa-button.usa-button--hover, .usa-button:hover, .adl-button a:hover {
  color: #ffffff !important;
  background-color: #578edd;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-link--external::after {
    display: inline-block;
  }
}

@media (max-width: 39.99em) {
  .inline-button {
    display: block;
    margin-top: 15px;
  }
  .adl-button a {
    width: 100%;
  }
}

// Definition Lists

dt {
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: bold;
}
dd {
  padding: 4px 0;
}

dl.adl-links dt {
  font-weight: normal;
  margin: 8px 0 0 0;
}
dl.adl-links dd {
  margin: 0 0 4px 0;
}

// Pagination

.usa-pagination .usa-current {
  background-color: #005ea2;
}

.usa-pagination__button{
  border-color:rgba(27, 27, 27, 0.2);
  color:#005ea2;
}
.usa-pagination__button:hover, .usa-pagination__button:focus, .usa-pagination__button:active{
  color:#1a4480;
  border-color:#1a4480;
}

.usa-pagination .usa-current{
  background-color:#005ea2;
  color:white;
}
.usa-pagination .usa-current:hover, .usa-pagination .usa-current:focus, .usa-pagination .usa-current:active{
  background-color:#005ea2;
  color:white;
}

// Search results page

#search-types {
  margin-top: .67em;
}
#search-types a.active {
  font-weight: bold;
}
.search-results-title {
  margin: .67em 0 0 0;
}

.usa-tag {
  margin-left: 5px;
  font-size: 0.6rem;
  padding: 0 .5rem;
  vertical-align: middle;
  background-color: #005ea2;
}

// Events Page Year

.events-year {
 background-color: #162E51;
 color: #fff;
 border-radius: 4px;
 font-size: 1.2rem;
}

// Hero Section Text Overlay Hover
.hero-container {
  position: relative;
  flex-wrap: inherit;
}

.hero-image {
  display: inline-block;
  width: auto;
  height: auto;
}

.hero-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #006699;
  border-radius: 0.25rem;
}

.hero-container:hover .hero-overlay {
  opacity: 1;
}

.hero-text {
  color: white;
  font-size: 1.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

// Home page sizes

.usa-hero__heading {
  font-size: 1.75rem;
}
.news_header, .events_header {
  font-size: 1.30rem;
}

.usa-collection__img {
  width: 9rem;
  border-radius: 0.25rem;
}

.usa-collection__item {
  max-width: 160ex;
}

// Home page events

@media (min-width: 40em) {
  .vertical-line {
    border-left: 1.25px solid #d2d6db;
    padding-left: 2rem !important;
  }
  .events_list {
    overflow-y: scroll;
    padding-right: 1rem;
    height: 700px;
  }
  .events_list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 13px;
    height:13px
  }
  .events_list::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #d2d6db;
    box-shadow:0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow:0 0 1px rgba(255, 255, 255, 0.5);
  }
  .events_list::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #f4f5f7;
    box-shadow:0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow:0 0 1px rgba(255, 255, 255, 0.5);
  }
}
@media (min-width: 52em) {
  .events_list {
    height: 700px;
  }
}
@media (min-width: 64em) {
  .events_list {
    height: 688px;
  }
}
@media (min-width: 72em) {
  .events_list {
    height: 600px;
  }
}

// Event Calendar w/Title and Location to the right

.event_container {
  min-height: 70px;
  position: relative;
}

.calendar_home_box {
  float: left;
}

.event_box, .calendar_home_box {
  width: 75px;
  height: 65px;
  text-align: center;
  line-height: 56px;
  background: #162E51;
  margin-right: 12px;
  border-radius: 0.25rem;
}
.event_box p, .calendar_home_box p {
  color: #ffffff;
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

.event_box .event_year, .calendar_home_box .event_year {
  font-size: smaller;
}

.calendar_home_text {
  margin-left: 70px;
}

.event_container .calendar_home_text {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  line-height: 1.2;
}

.calendar_home_text h3 {
  padding: 0;
  margin: 0;
  line-height: 1.2;
}

.calendar_home_text p {
  margin-top: 4px;
  margin-bottom: 0;
  margin-left: 18px;
}
.calendar_home_text .usa-button {
  margin-top: 1em;
}

// Home page announcements

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .announcement_home {
    max-width: 34vw;
  }
}

// .announcement_home {
//   border-bottom: 1px solid #223562;
// }

@media all and (min-width: 30em) {
  .announcement_home .usa-button {
    min-width: 150px;
    white-space:nowrap;
    margin-right: 0;
    margin-left: .5rem;
    border-radius: 0.25rem;
  }
}

// Alert Info
.usa-alert--info {
  color: #162E51;
  border-left-color: #162E51;
}

// Footer Section

.usa-footer{
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

.usa-footer__primary-content{
  color: #fff;
}

.usa-footer__secondary-link a {
  color: #fff;
}

.usa-footer__secondary-link a:hover{
  color: #fff;
}


.usa-footer__secondary-link a:visited{
  color: #fff;
}

.usa-footer--big .usa-footer__primary-link{
  font-size: 1.1rem;
}

.usa-footer__primary-link a, .usa-footer__secondary-link a{
  text-decoration: none;
}

.usa-footer--big .usa-footer__primary-content--collapsible .usa-footer__primary-link{
  color: #fff;
  cursor: auto;
}

.usa-footer__return-to-top {
  scroll-behavior: smooth;
}

footer {
  .add-list-reset {
    padding-left: 10px;
  }
}

.usa-footer__logo-heading {
  font-size: 1.4rem;
  line-height: 1.2;
}

.usa-footer {
  line-height: 1.8;
  border-top: 5px solid #e7f6f8;
}
.footer-section-bottom {
  background-color: #069;
}
.footer-section-bottom li {
  font-size: 1rem;
  color: white;
}
.footer-section-bottom li a {
  color: white;
  text-decoration: none;
  white-space: nowrap;
}

.usa-social-link {
  background-color: #069;
}

.usa-social-link__icon {
  display: block;
  height: auto;
  width: 100%;
  background-color: #069;

  &:hover {
    background-color: #069;
  }
}

.usa-footer__primary-link {
  font-size: 1.06rem;
  font-weight: 400;
}

.usa-footer__logo-img {
  max-width: 11rem;
}

.usa-footer__primary-section {
  background-color: #069;
}

@media screen and (min-width: 40em){
.usa-footer__primary-section{
  margin-bottom: -0.75rem;
  }
}

.usa-footer__primary-section a {
  color: #fff;
}

.usa-footer__secondary-section {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  overflow: hidden;
  color: #fff;
  background-color: #069;
}

.usa-footer__contact-info a {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .footer-section-bottom li {
    line-height: 2.2;
  }
}


// Modal Videos

.video-link img {
  cursor: pointer;
}

.modal-dialog {
  min-width: 75vw;
  max-height: 100vw;
  margin: 30px auto;
}

.modal-body {
  min-width: 75vw;
  max-height: 75vw;
  position: relative;
  padding: 0;
  background: transparent;
}

// Video player thumbnail interface

.video-player {
  position: relative;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #c6c6c6;
  line-height: 0;
  border-radius: 0.25rem;
}
.video-player .video-link {
  position: relative;
}
.video-player .video-link img {
  position: relative;
  margin: 0;
  border-width: 0;
  border-style: solid;
  -webkit-transition: .4s all;
  transition: .4s all;
}
.video-player .video-play {
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  color: #e7e3fa;
  text-align: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: .5rem;
  background-color: rgba(0,0,0,.85);
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  pointer-events: none;
}
.video-player .video-play::after {
  position: relative;
  top: .75rem;
  left: 2px;
  content: " ";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 0 14px 24px;
  border-color: transparent transparent transparent #fff;
}
.video-player .video-time {
  position: absolute;
  right: 0.5rem;
  bottom: 0.6rem;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
  cursor: pointer;
  pointer-events: none;
  color: #fafafa;
  background-color: #2a2832;
  font-size: unset;
  padding: 12px 6px;
}
.video-time {
  color: #fafafa;
  background-color: #005ea2;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: small;
}
.video-player:hover .video-play {
  background-color:#578edd;
}
.video-action {
  text-align: center;
  margin: 4px 0 0 0;
}

// Image link

.image-link {
  border: 1px solid #b6b6b6;
}

.image-link:hover {
  border: 1px solid #578edd;
}

// Swiper

.swiper-outer-container {
  position: relative;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 39.99em) {
  .swiper-outer-container {
    padding: 0 6px;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.99em){
  .swiper-outer-container {
    padding: 0 10vw 15px 10vw;
  }
}

.swiper {
  height: 100%;
  width: 100%;
  border-radius: 0.75rem;
}

.swiper-wrapper {
  margin-bottom: 30px;
}

.swiper-slide {
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-slide-caption {
  padding: 15px 30px 30px 30px;
}

.swiper-slide-caption h3 {
  margin:0;
  font-size:calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  .swiper-slide-caption h3 {
    font-size:1.75rem;
  }
}


.swiper-slide-caption p {
  margin-bottom: 4px;
  margin-top: 4px;
  line-height: 1.4;
}

.swiper-slide-caption a {
  color: #ffffff;
}

/* Swiper Text Options */

.swiper-slide-caption.swiper-text-light h3,
.swiper-slide-caption.swiper-text-light h4,
.swiper-slide-caption.swiper-text-light h5,
.swiper-slide-caption.swiper-text-light p {
  color: #ffffff;
}

.swiper-slide-caption.swiper-text-dark h3,
.swiper-slide-caption.swiper-text-dark h4,
.swiper-slide-caption.swiper-text-dark h5,
.swiper-slide-caption.swiper-text-dark p {
  color: #000000;
}

/* Background Options */

.background-dark {
  border-top: 1px solid #000000;
  background-color: #000000;
  background-color: rgba(10,10,10,0.8);
}

.background-gray {
  border-top: 1px solid #8d8d8d;
  background-color: #8d8d8d;
  background-color: rgba(200,200,200,0.5);
}

/* Swiper Components */

.swiper-button-prev, .swiper-button-next {
  background-color: rgb(51,51,51);
  background-color: rgba(0,0,0,0.2);
  background-size: 80%;
  padding: 5px;
  top: 44%;
  width: 50px;
  height: 75px;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next { left: 0; }
.swiper-button-next, .swiper-rtl .swiper-button-prev { right: 0; }
.swiper-button-prev:hover, .swiper-button-next:hover {
  background-color: rgb(76,76,76);
  background-color: rgba(200,200,200,0.3);
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-feature-settings: normal;
}
.swiper-button-prev.swiper-button-white,
.swiper-button-next.swiper-button-white {
  color: #ffffff;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 4px;
}
.swiper-pagination-bullet {
  border: 1px solid #ffffff;
  background: #444;
  opacity: 0.2;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--swiper-pagination-color,var(--swiper-theme-color));
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .swiper-button-prev::after {
    content: "<";
    font-size: 30px;
  }
  .swiper-button-next::after {
    content: ">";
    font-size: 30px;
  }
}

// Sticky

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}
.sticky::before,
.sticky::after {
  content: '';
  display: table;
}

// Backgrounds

.usa-section--dark {
  background-color: #162E51;
  color: #fff;
}

// Side Nav

.usa-sidenav{
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

.sticky-subnav {
  padding-top: 0;
}

.subnav {
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
  padding: 0;
}

.adl-sidenav__title {
  color: #162E51;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 5px 16px;
}

.usa-sidenav .usa-current:after {
  top: 0;
  bottom: 0;
  border-radius: 0;
}

.usa-sidenav__item a, .usa-sidenav a:not(.usa-button) {
  color: #005ea2;
}

.usa-sidenav__sublist a, .usa-sidenav__sublist a:not(.usa-button) {
  padding-left: 2rem;
}

.usa-sidenav .usa-current::after {
  background-color: #162E51;
}

.usa-sidenav a:not(.usa-button):not(.usa-current) {
  color: #162e51;
}

// Integrate sidenav into mobile menu

@media (max-width: 39.99em) {
  .site-nav-secondary {
    display: none;
  }
  .usa-layout-docs-sidenav {
    display: none;
  }
}
@media (min-width: 40em) {
  .site-nav-mobile {
    display: none;
  }
}

// Table of Contents

.adl-toc {
  border-left: 2px solid #d4d7db;
  padding-top: 1em;
  padding-left: 1em;
}
.adl-toc, .adl-toc a {
  color: #6c7681;
}
.adl-toc a {
  text-decoration: none;
}
.adl-toc a:hover {
  color: #005ea2;
}
.adl-toc h6 {
  color: #6c7681;
  padding: 0;
  margin: 0;
}
.adl-toc ul, .adl-toc ol {
  list-style: none;
  margin-left: -40px;
}
.adl-toc li {
  margin-bottom: 1em;
}

.adl-toc ul ul {
  margin-top: 1em;
}

// Profile
.profile {
  height: 140%;
}

@media (min-width: 40em) { 
  .usa-card--flag .usa-card__media{
  border-radius: 0.45rem 0 0 0.45rem;
  }
}

// Components

figure {
  margin: 0;
  padding: 16px 0;
}

.figure-right {
  float: right;
  margin: 0 0 16px 24px;
  padding: 16px;
}

.figure-left {
  float: left;
  margin: 0 24px 16px 0;
  padding: 16px;
}

.modal-image, .pdf-image {
  position: relative;
}

.modal-container, .pdf-image-container {
  position: relative;
}

.modal-container:hover .modal-instructions,
.pdf-image-container:hover .modal-instructions{
  background-color: #57ABFB;
}

.modal-image .modal-instructions,
.pdf-image .modal-instructions {
  color: #ffffff;
  background-color: #3f76c5;
  position: absolute;
  bottom: 8px;
  right: 1px;
  text-align: right;
  padding: 2px 6px;
  font-size: small;
  cursor: pointer;
  pointer-events: none;
}
@media screen and (max-width: 39.99em) {
  .modal-image .modal-instructions,
  .pdf-image .modal-instructions{
    display: none;
  }
}

.modal-image img:hover,
.pdf-image img:hover {
  cursor: pointer;
}

.modal-content-main img {
  max-height: 80vh;
}

.image-left {
  float: left;
  margin: 4px 24px 16px 0;
  padding: 0;
}

.image-right {
  float: right;
  margin: 4px 0 16px 24px;
  padding: 0;
}

.image-half {
  width: 450px;
}

.image-detail {
  max-width: 250px;
  max-height: 250px;
  height: auto;
  border-radius: 0.75rem;
}

figure p, figcaption {
  width: 250px;
  font-size: smaller;
}

img.padding-1 {
  padding:0.5rem !important;
  border-radius: 4px;
}

img.padding-2 {
  padding:1em !important;
  border-radius: 4px;
}

img.padding-3 {
  padding:1.5rem !important;
  border-radius: 4px;
}

figcaption.image-caption-150 {
  width: 150px;
}

figcaption.image-caption-200 {
  width: 200px;
}

figcaption.image-caption-225 {
  width: 225px;
}

figcaption.image-caption-275 {
  width: 275px;
}

figcaption.image-caption-300 {
  width: 300px;
}

figcaption.image-caption-325 {
  width: 325px;
}

figcaption.image-caption-375 {
  width: 375px;
}

figcaption.image-caption-half {
  width: 450px;
}

figcaption.image-caption-full {
  width: unset;
}

.usa-prose li figure.image-right {
  margin-right: 0;
}

.text-callout {
  background-color: #dee8f0;
}

.text-callout-quote {
  background-color: #f5f5f5;
}

@media screen and (max-width: 54.99em) {
  .image-left, .image-right, .image-half,
  figure.image-left img, figure.image-right img, figure.image-half img,
  .figure-left, .figure-right {
    float: unset;
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%;
    height: auto;
  }
  figure p, figcaption {
    width: 100% !important;
  }
  .text-callout {
    margin-top: 20px;
  }
  .small-maxw-full {
    width: 100%;
  }
}

.image-list a {
  padding-right: 2px;
}

figure p {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-style: italic;
  font-size: 1rem;
  text-transform: none;
}

.text-callout p {
  font-weight: bold;
  font-size: 1rem;
}

@media screen and (max-width: 39.99em) {
  figure p {
    padding: 16px;
  }
}

.image-caption {
  padding: 0 !important;
  margin: 0 !important;
  font-weight: bold;
  font-style: italic;
  font-size: 1rem;
  text-transform: none;
}

@media screen and (min-width: 40em) {
  .tablet\:image-y-100 {
    height: 100px;
    width: auto;
  }
}

/* Cards */

.borderless-card-group .usa-card__container {
  border: none;
}
.borderless-card-group .usa-card__header,
.borderless-card-group .usa-card__body,
.borderless-card-group .usa-card__footer {
  padding-top: 0;
  padding-left: 0;
}

@media screen and (max-width: 39.99em) {
  .borderless-card-group .usa-card__container .usa-card__header,
  .borderless-card-group .usa-card__container .usa-card__footer {
    text-align: center;
  }
}

.minh-grid-card {
  min-height:9rem;
}
.grid-item-label {
  line-height: 1.1;
}

// Lists

@media screen and (max-width: 39.99em) {
  .mobile-only\:list-margin-y-0 ul {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.footnotes ol {
  padding-left: 22px;
}

ul.hide-bullets-2 ul {
  list-style: none;
  margin-left: -40px;
}

ul.ul-li-top-1 li {
  margin-top: 10px;
}
ul.ul-li-top-1 li:first-child {
  margin-top: 0;
}
ul.ul-li-top-1 ul li:first-child {
  margin-top: 20px;
}

ul.ul-li-top-2 li {
  margin-top: 20px;
}
ul.ul-li-top-2 li:first-child {
  margin-top: 0;
}
ul.ul-li-top-2 ul li:first-child {
  margin-top: 20px;
}
ul li ul {
  margin-block-end: 0.5em;
}

// Tables

.usa-accordion, .usa-prose, .usa-prose > h6{
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

.usa-prose .usa-prose > table, .usa-prose > table caption, .usa-table, .usa-table caption{
  font-family: Public Sans Web,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}

.usa-table thead th, .usa-prose > table thead th,
.usa-table thead td,
.usa-prose > table thead td {
  background-color: #162E51;
  color: #ffffff;
}
.usa-table tbody td,
.usa-prose > table tbody td,
.usa-prose > table tbody th {
  vertical-align: top;
}
.usa-prose>table caption, .usa-table caption {
  font-size: 1.2rem;
}
.usa-table td p:first-child {
  margin-top: 0;
  margin-block-start: 0;
}
.usa-table ul {
  margin-top: 0;
}
.usa-table ul ul {
  padding-left: 3ch;
}
@media screen and (max-width: 63.99em) {
  .usa-table.stacked thead {
    display: none;
  }
  .usa-table.stacked thead th,
  .usa-table.stacked tbody th,
  .usa-table.stacked tbody td {
    display: block;
    border: none;
    width: 100%;
  }
  .usa-table.stacked tbody th {
    font-weight: bold;
  }
}

.adl-table-nowrap-col-1 tr td:first-of-type,
.adl-table-nowrap-col-2 tr td:nth-of-type(2),
.adl-table-nowrap-col-3 tr td:nth-of-type(3),
.adl-table-nowrap-col-4 tr td:nth-of-type(4),
.adl-table-nowrap-col-5 tr td:nth-of-type(5) {
  white-space: nowrap;
}

.adl-table-120-col-1 tr th:first-of-type,
.adl-table-120-col-2 tr th:nth-of-type(2),
.adl-table-120-col-3 tr th:nth-of-type(3),
.adl-table-120-col-4 tr th:nth-of-type(4),
.adl-table-120-col-5 tr th:nth-of-type(5) {
  min-width: 120px;
}

.adl-table-250-col-1 tr th:first-of-type,
.adl-table-250-col-2 tr th:nth-of-type(2),
.adl-table-250-col-3 tr th:nth-of-type(3),
.adl-table-250-col-4 tr th:nth-of-type(4),
.adl-table-250-col-5 tr th:nth-of-type(5) {
  min-width: 250px;
}

.adl-table-even-2 tr td {
  width:50%;
}
.adl-table-even-3 tr td {
  width:33%;
}
.adl-table-even-4 tr td {
  width:25%;
}
.adl-table-even-5 tr td {
  width:20%;
}
.adl-table-even-6 tr td {
  width:16.6666666667%;
}

// Show More button (uses checkbox + div + label)

.adl-show-more {
  position: absolute;
  left: -9999px;
}
.adl-show-more-label {
  color: #1b1b1b !important;
  //background: #eff0f1;
  font-size: 13px;
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: pointer;
  //padding: 6px;
  margin-bottom: 20px;
}
.adl-show-more-label:hover {
  color: #1b1b1b !important;
  text-decoration: underline;
}

.adl-show-more + .adl-show-more-content + .adl-show-more-label:before {
  content: 'Show more';
}
.adl-show-more + .adl-show-more-content + .adl-show-more-label:after {
  content: ' ⇣';
}
.adl-show-more + .adl-show-more-content {
  display: none;
}

.adl-show-more:checked + .adl-show-more-content + .adl-show-more-label:before {
  content: 'Show less';
}

.adl-show-more:checked + .adl-show-more-content + .adl-show-more-label:after {
  content: ' ⇡';
}

.adl-show-more:checked + .adl-show-more-content {
  display: block;
}

// Accordion

.usa-accordion__button {
  //color: #ffffff;
  //background-color: #3f76c5;
  //background-image:url("../images/uswds/remove--white.svg"), linear-gradient(transparent, transparent);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.usa-accordion__button:hover {
  //color: #ffffff;
  //background-color: #57ABFB;
}

.usa-accordion__button[aria-expanded=false]{
  //background-image:url("../images/uswds/add--white.svg"), linear-gradient(transparent, transparent);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.usa-accordion--bordered .usa-accordion__content {
  //border-bottom: .25rem solid #578EDD;
  //border-left: .25rem solid #578EDD;
  //border-right: .25rem solid #578EDD;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

// Tooltips

// Tooltips don't work on mobile
@media screen and (max-width: 39.99em) {
  .usa-tooltip__body {
    display: none !important;
  }
}
@media screen and (min-width: 40em) {
  .adl-definition {
    color: #000000 !important;
    border-bottom: 2px dotted #ff0000;
    text-decoration: none;
  }
}

.usa-tooltip__body {
  width: 275px;
  white-space: normal;
  background-color: #069;
}
.usa-tooltip__body--wrap {
  white-space: normal;
}
.usa-tooltip__body--top:after {
  border-top: 5px solid #069;
}
.usa-tooltip__body--bottom:after {
  border-bottom: 5px solid #069;
}

.usa-tooltip__body--right:after{
  border-right:5px solid #069;
}

.usa-tooltip__body--left:after{
  border-left:5px solid #069;
}

/* Portfolio */

.portfolio-caption {
  background-color: #069;
  color: #fff;
  padding: 20px;
  margin-bottom: 0;
  margin-top: 10px;
}

.portfolio-section {
  background-color: #069;
  padding: 10px 30px;
}

.portfolio-section,
.portfolio-section p,
.portfolio-section a,
.portfolio-section a:visited {
  color: #fff;
}

.portfolio-callout {
  background-color: #dee8f0;
  border-radius: .75rem;
}

.portfolio-callout h3, .portfolio-callout h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.portfolio-section .event_box {
  background-color: #b5ccdf;
  border: 1px solid #223562;
}
.portfolio-section .event_box p {
  color: #223562;
}

@media print{
  .hide-for-print {
    display: none;
  }
  .usa-layout-docs__main {
    width: 100%;
  }
}
